<div class="mb-lg-5">
  <!-- <ul>
  <li (click)="switchLanguage('en')"><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>
    English</a></li>
<li (click)="switchLanguage('es')"><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>
    Spanish</a></li>
</ul> -->


  <!-- Tiles -->
  <div class="row mt-minus-12 tiles" *ngIf="dashboard.tiles">
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/leads">
        <div class="custom-blue card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb-lead"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK1_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.leads}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden  widget-cards" routerLink="/customers">
        <div class="custom-Rebeccapurple  card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-customer"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK2_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.cust}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/projects">
        <div class="custom-Ruby card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-project"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK3_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.proj}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/projecttask">
        <div class="custom-vividOrange card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-project-task"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK4_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.tasks}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/servicetickets">
        <div class="custom-vividcyan card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="fa fa-ticket"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK5_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.tickets}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/suppliers">
        <div class="custom-CadetBlue card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-suppliers"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK6_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.suppliers}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/invoices">
        <div class="custom-OutrageousOrange card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-invoices"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK7_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.inv}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/quotes">
        <div class="custom-Hoki card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-estimate-add"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK8_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.qte}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/products">
        <div class="custom-black card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-product"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK9_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.prods}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/salesorders">
        <div class="custom-Rebeccapurple card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-sales-order"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK10_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.orders}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/servicecontracts">
        <div class="custom-AmberGold card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb_contract"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK11_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.licenses}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/expenses">
        <div class="custom-Hoki card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-expenses"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK12_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.expense}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/salesreq">
        <div class="custom-blue card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class=" eb-sales-request"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK13_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.salesrequests}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/purchaserequests">
        <div class="custom-Ruby card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb-purchase"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK14_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.purchaserequests}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/assets">
        <div class="custom-vividcyan  card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb-assets"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK15_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.assets}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/purchaseorder">
        <div class="custom-OutrageousOrange  card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb-purchase1"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK16_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.pos}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/employees">
        <div class="custom-black  card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="fa fa-users"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK17_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.employees}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-20">
      <div class="card o-hidden widget-cards" routerLink="/timeline">
        <div class="custom-vividcyan  card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <i class="eb-scheduler"></i>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">{{ 'HOME.BLOCKS.BLOCK18_TITLE' | translate }}</span>
              <h3 class="mb-0">{{counts.schedules}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- Add Accountants -->
    <div class="col-lg-12 col-sm-12 p-1" *ngIf="dashboard.salessummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>Connect with an Accountant</h5>
        </div>
        <div class="card-body position-relative">
          <div class="form-group">
            <div class="d-flex m-252">
              <div>
                <h5 class="mb-0">Get setup right with the help of an accountant</h5>
                <div>Invite them to your books so you can finish setting up together.</div>
              </div>
              <div class="field-section ml-4">
              <div class="form-group d-flex width-100">
                <input class="form-control accountant-field" placeholder="Accountant's Email"> 
                <button class="btn btn-primary invite-btn">Invite</button>
              </div>
              <div class="find-accountant">No Accountant? <span class="blue-link">Find a pro to help</span></div>
              </div>
              <div class="accountant-icon ml-4">
                <!-- <img src="assets/images/account.jpg"> -->
                <img src="assets/images/accountant.jpg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sales Summary -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.salessummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'salessummary_title' | translate }}</h5>
        </div>
        <div class="card-body">
          <div class="picsum-img-wrapper">
            <div [chart]="salesChart" *ngIf="salesChart"></div>
            <div class="text-center notfound" *ngIf="!salesChart">
              Records not found
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Purchase Summary -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.purchasesummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'PurchaseSummary_text' | translate }}</h5>
        </div>
        <div class="card-body">
          <div class="picsum-img-wrapper">
            <div [chart]="purchaseChart" *ngIf="purchaseChart"></div>
            <div class="text-center notfound" *ngIf="!purchaseChart">
              Records not found
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Recent Transactions -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.recenttransactions">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'RecentTransactions_text' | translate }}</h5>
          <div class="right-div">
            <div class="searchbox">
              <input class="form-control" placeholder="{{ 'Search_text_placholder' | translate }}" #myInput
                type="search" (input)="searchTransactions(myInput.value)">
              <button (click)="searchTransactions(myInput.value)">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="type-drop">
              <select class="type-select form-control" name="type" [(ngModel)]="transType"
                (change)="searchTransactions(transType)">
                <option value="">{{ 'Search_placeholder' | translate }}</option>
                <option [value]="n.Value" *ngFor="let n of recentTrTypeList">{{n.Text}}</option>
              </select>
            </div>
            <!-- <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem> Delete Transactions</span></li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
        <div class="card-body">
          <div class="category-table custom-datatable dashboard-table">
            <div class="table-responsive3">
              <table class="custom-table">
                <thead>
                  <tr class="custom-tr">
                    <!-- <th class="custom-th text-center">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [ngValue]="true" id="flexCheckDefault">
                      </div>
                    </th> -->
                    <th class="custom-th text-center" (click)="onSortClick($event)">
                      {{ 'Recenttransaction_Table.Txndate_column' | translate }} <i
                        class="Sorting fa fa-chevron-up"></i>
                    </th>
                    <th class="custom-th text-center" (click)="onSortClick($event)">
                      {{ 'Recenttransaction_Table.Customer_column' | translate }} <i
                        class="Sorting fa fa-chevron-up"></i>
                    </th>
                    <th class="custom-th text-center" (click)="onSortClick($event)">
                      {{ 'Recenttransaction_Table.Number_column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
                    </th>
                    <th class="custom-th text-center" (click)="onSortClick($event)">
                      {{ 'Recenttransaction_Table.Amount_column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
                    </th>
                    <th class="custom-th text-center" (click)="onSortClick($event)">
                      {{ 'Recenttransaction_Table.Status_column' | translate }} <i class="Sorting fa fa-chevron-up"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-tr" *ngFor="let n of transactions | slice:0:10 let i=index">
                    <!-- <td class="custom-td text-center">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [value]="n.id" id="flexCheckDefault1"
                          [(ngModel)]="n.isSelected" (change)="isAllSelected()">
                      </div>
                    </td> -->
                    <td class="custom-td text-center">
                      {{n.txndate}}
                    </td>
                    <td class="custom-td text-left">
                      {{n.customer}}
                    </td>
                    <td class="custom-td text-left">
                      {{n.num}}
                    </td>
                    <td class="custom-td text-right">
                      {{n.amt}}
                    </td>
                    <td class="custom-td text-center">
                      <span class="badge" [title]="n.status">{{n.status}}</span>
                    </td>
                  </tr>
                </tbody>
                <div class="text-center notfound" *ngIf="transactions.length == 0">
                  Records not found
                </div>
              </table>
              <!-- <ng2-smart-table [settings]="settings" [source]="transactions"></ng2-smart-table> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reminders -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.reminders">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Reminder_section.Reminders_title' | translate }}</h5>
          <div class="right-div">
            <div class="searchbox">
              <input class="form-control" placeholder="{{ 'Reminder_section.Search_placholder' | translate }}"
                name="searchtext" [(ngModel)]="reminderFilter.searchtext" (input)="searchReminder()" type="search">
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="type-drop">
              <select class="type-select form-control" name="type" [(ngModel)]="reminderFilter.type"
                (change)="searchReminder()">
                <option value="">{{ 'Reminder_section.All_placholder' | translate }}</option>
                <option [value]="typ.stringValue" *ngFor="let typ of reminderTypes">{{typ.Text}}</option>
              </select>
            </div>
            <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="openReminderModal(content,'')">{{
                      'Reminder_section.Options.Add_reminder' | translate }}</span></li>
                  <li><span ngbDropdownItem (click)="markRead()">{{ 'Reminder_section.Options.MarkRead_option' |
                      translate }}</span></li>
                  <li><span ngbDropdownItem (click)="deleteReminder()">{{ 'Reminder_section.Options.Delete_options' |
                      translate }}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="table-responsive3 col-md-12">
              <table class="custom-table">
                <thead>
                  <tr class="custom-tr">
                    <th class="custom-th text-center">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [ngValue]="true" id="flexCheckDefault2"
                          [(ngModel)]="masterSelected" (change)="checkUncheckAll()">
                      </div>
                    </th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Duedate_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Title_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Number_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Type_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Department_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Status_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Reminder_section.Table.Actions_column' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-tr" *ngFor="let n of filteredReminders | slice:0:10 let i=index">
                    <td class="custom-td text-center">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="ischecked-{{i}}" id="flexCheckDefault3"
                          [(ngModel)]="n.ischecked">
                      </div>
                    </td>
                    <td class="custom-td text-center">
                      {{n.due_date | date:format}}
                    </td>
                    <td class="custom-td text-left">
                      {{n.title}}
                    </td>
                    <td class="custom-td text-left">
                      {{n.doc_no}}
                    </td>
                    <td class="custom-td text-center">
                      <span class="dropdown-item badge cursor-pointer" data-toggle="modal" title="{{ n.type }}"
                        data-target="#exampleModal">
                        <i class=" eb-sales-order" *ngIf="n.type == 'salesorder'"></i>
                        <i class="eb-assets" *ngIf="n.type == 'assets'"></i>
                        <i class="eb-assets" *ngIf="n.type == 'assreq'"></i>
                        <i class="eb-project-task" *ngIf="n.type == 'customdeclarations'"></i>
                        <i class="eb-invoices" *ngIf="n.type == 'invoices'"></i>
                        <i class="fa fa-exchange" *ngIf="n.type == 'internaltranferrequests'"></i>
                        <i class="eb-purchase1" *ngIf="n.type == 'purchaseorders'"></i>
                        <i class="eb-project" *ngIf="n.type == 'projects'"></i>
                        <i class="eb-estimate-add" *ngIf="n.type == 'quotes'"></i>
                        <i class="fa fa-bug" *ngIf="n.type == 'snags'"></i>
                        <i class="eb-project-task" *ngIf="n.type == 'tasks'"></i>
                        <i class="fa fa-ticket" *ngIf="n.type == 'tickets'"></i>
                        <i class="eb-inventory" *ngIf="n.type == 'inventorylotexpiry'"></i>
                        <i class="eb-sales-request" *ngIf="n.type == 'salesrequests'"></i>
                        <i class="eb-purchase" *ngIf="n.type == 'purchaserequests'"></i>
                        <i class="eb_contract" *ngIf="n.type == 'contracts'"></i>
                        <i class="eb-payroll" *ngIf="n.type == 'ar'"></i>
                        <i class="eb-expenses" *ngIf="n.type == 'arap'"></i>
                        <i class="eb-payroll" *ngIf="n.type == 'prpp'"></i>
                        <i class="fa fa-level-down" *ngIf="n.type == 'inventorybelowmin'"></i>
                        <i class="fa fa-archive" *ngIf="n.type == 'inventorypendingrestock'"></i>
                        <i class="fa fa-blind" *ngIf="n.type == 'leave'"></i>
                        <i class="fa fa-tachometer" *ngIf="n.type == 'fsn'"></i>
                        <i class="fa fa-sticky-note" *ngIf="n.type == 'notes'"></i>
                        <i class="fa fa-refresh" *ngIf="n.type == 'datasync'"></i>
                        {{ n.type }}</span>
                    </td>
                    <td class="custom-td text-left">
                      {{n.dept}}
                    </td>
                    <td class="custom-td text-center">
                      {{n.status}}
                    </td>
                    <td class="custom-td text-center">
                      <div ngbDropdown class="d-inline-block ActionCell" container="body">
                        <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                        <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                          <li><span ngbDropdownItem (click)="openReminderModal(content, n.id)">{{
                              'Reminder_section.Options2.edit_option' | translate }}</span>
                          </li>
                          <li><span ngbDropdownItem (click)="markReadConfirmation([n.id])">{{
                              'Reminder_section.Options2.Markread_option' | translate }}</span></li>
                          <li><span ngbDropdownItem (click)="deleteReminderConfirmation([n.id])">{{
                              'Reminder_section.Options2.Delete_options' | translate }}</span></li>
                          <!-- <li><span ngbDropdownItem>Add to Calendar</span></li> -->
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center notfound" *ngIf="!reminderList">
                Records not found
              </div>
              <div class="see-more" routerLink="/reminders">See More</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Clocking -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.clockin">
      <div class="empDashboard">

        <div class="card mb-1">
          <div class="card-header">
            <h5>{{ 'TimelogSection.Title' | translate }}</h5>
            <div class="row timeDate">
              <div>
                <i class="eb-calendar mr-1"></i>
                <span>{{currentDateTime | date:'MMM d, y'}}</span>
              </div>
              <div>
                <i class="eb-time mr-1"></i>
                <span>{{currentDateTime | date:'h:mm:ss a'}}</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-12 mb-3">
                <div class="timeBlock">
                  <span>{{ 'TimelogSection.total_hours' | translate }}</span>
                  <h3>{{totalWorkTime}}</h3>
                </div>
              </div>
              <div class="col-xl-6 col-md-12 mb-3">
                <div class="timeBlock">
                  <span>{{ 'TimelogSection.Total_break_Hours' | translate }}</span>
                  <h3>{{totalBreakTime}}</h3>
                </div>
              </div>
            </div>
            <div class="checkInBtn">
              <div class="mainBtn" *ngIf="shiftStarted">
                <div class="btnBlock">
                  <div class="custom-control custom-switch">
                    <input [checked]="brkStarted && shiftStarted" type="checkbox" class="custom-control-input"
                      id="breakBtnSwitch">
                    <label class="custom-control-label" for="breakBtnSwitch"
                      (click)="clockingModal(clockModal,'B');"></label>
                  </div>
                </div>
                <div class="btnTitle" *ngIf="!brkStarted && shiftStarted">{{ 'TimelogSection.StartBreak' | translate }}
                </div>
                <div class="btnTitle" *ngIf="brkStarted && shiftStarted">{{ 'TimelogSection.EndBreak' | translate }}
                </div>
              </div>
              <div class="mainBtn cursor-pointer">
                <div class="btnBlock" (click)="clockingModal(clockModal, 'I')" *ngIf="!shiftStarted">
                  <i class="eb-checkIn"></i>
                </div>
                <div class="btnBlock" (click)=" clockingModal(clockModal, 'O')" *ngIf="shiftStarted">
                  <i class="eb-checkOut"></i>
                </div>
                <div class="btnTitle" *ngIf="!shiftStarted">{{ 'TimelogSection.Check_IN' | translate }}</div>
                <div class="btnTitle" *ngIf="shiftStarted">{{ 'TimelogSection.Check_Out' | translate }}</div>
              </div>

            </div>
            <div class="category-table custom-datatable">
              <div class="table-responsive2" style="min-height: 275px;">
                <table class="custom-table">
                  <thead>
                    <tr class="custom-tr">
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.date_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.StartTime_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.EndTime_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Duration_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Type_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Reason_column' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="custom-tr">
                      <td class="custom-td text-center">{{currentDateTime |
                        date:'yyy-MM-dd'}}
                      </td>
                      <td class="custom-td text-center">
                        {{startMainTimeinhours}}:{{startMainMin}}
                      </td>
                      <td class="custom-td"></td>
                      <td class="custom-td"></td>
                      <td class="custom-td">{{ 'TimelogSection.Table.Check_In' | translate }}</td>
                      <td class="custom-td"></td>
                    </tr>
                    <tr class="custom-tr" *ngFor="let item of timing">
                      <td></td>
                      <td class="custom-td text-center">
                        {{item.startHrs}}:{{item.startMin}}</td>
                      <td class="custom-td text-center"><span
                          *ngIf="item.endHrs!='--'">{{item.endHrs}}:{{item.endMin}}</span>
                      </td>
                      <td class="custom-td"></td>
                      <td class="custom-td text-center">{{ 'TimelogSection.Table.Break' | translate }}</td>
                      <td class="custom-td"></td>
                    </tr>
                  </tbody>
                </table>
                <table class="custom-table">
                  <thead>
                    <tr class="custom-tr">
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Date_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Start_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Endtime_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Break_total_column' | translate }}</th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Working_total_column' | translate }}
                      </th>
                      <th class="custom-th text-center">{{ 'TimelogSection.Table.Reason__column' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="custom-tr" *ngFor="let item of officeClockings">
                      <td class="custom-td text-center">{{item.dow | date:
                        "yyyy-MM-dd"}}</td>
                      <td class="custom-td text-center">{{item.start_time }}</td>
                      <td class="custom-td text-center"><span *ngIf="item.end_time!='--'">{{item.end_time}}</span>
                      </td>
                      <td class="custom-td text-right">{{item.break_total | number
                        :'1.2-2'}}</td>
                      <td class="custom-td text-right">{{item.total_hrs | number
                        :'1.2-2'}}</td>
                      <td class="custom-td"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Leaves -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.leaves">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Employee_leave_section.Title' | translate }}</h5>
        </div>
        <div class="card-body">
          <div class="category-table custom-datatable">
            <div class="table-responsive3">
              <table class="custom-table">
                <thead>
                  <tr class="custom-tr">
                    <th class="custom-th text-center"><a class="add-table-button cursor-pointer" ngbTooltip="Add Leave"
                        (click)="addLeave('','')"><i class="fa fa-plus"></i> </a>
                    </th>
                    <th class="custom-th text-center">{{ 'Employee_leave_section.Table.Leave_type_column' | translate }}
                    </th>
                    <th class="custom-th text-center">{{ 'Employee_leave_section.Table.FromDate_column' | translate }}
                    </th>
                    <th class="custom-th text-center">{{ 'Employee_leave_section.Table.Todate_column' | translate }}
                    </th>
                    <th class="custom-th text-center">{{ 'Employee_leave_section.Table.Days_column' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Employee_leave_section.Table.Status_column' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-tr" *ngFor="let item of employeeLeaveList">
                    <td class="custom-td text-center">
                      <div ngbDropdown class="d-inline-block ActionCell" container="body">
                        <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                        <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                          <li><span ngbDropdownItem (click)="addLeave(item,true)">{{
                              'Employee_leave_section.Options.view_options' | translate }}</span></li>
                          <li><span ngbDropdownItem (click)="addLeave(item,false)">{{
                              'Employee_leave_section.Options.Edit_options' | translate }}</span></li>
                          <li><span ngbDropdownItem>{{ 'Employee_leave_section.Options.Delete_options' | translate
                              }}</span></li>
                        </ul>
                      </div>
                    </td>
                    <td class="custom-td">{{item.type}}</td>
                    <td class="custom-td text-center">{{item.fromdate | date:format}}</td>
                    <td class="custom-td text-center">{{item.todate | date:format}}</td>
                    <td class="custom-td text-center">{{item.tlp}}</td>
                    <td class="custom-td text-center"><span class="badge" title="{{item.status}}">{{item.status}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center notfound" *ngIf="employeeLeaveList.length == 0">
                Records not found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- World Clock -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.worldclok">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Worldclocksection.World_clock' | translate }}</h5>
        </div>
        <div class="card-bod height-strecht">
          <div class="row worldClockBlock" *ngIf="timeZoneList.length>0">
            <div class="form-group" [class.col-lg-6]="formatedList.length <= 2"
              [class.col-lg-4]="formatedList.length > 2" *ngFor="let clock of formatedList">
              <div class="clock" style="margin-bottom: 60px;">
                <div class="analog-clock">
                  <div class="hour hand" [ngStyle]="clock.hourHandStyle"></div>
                  <div class="minute hand" [ngStyle]="clock.minuteHandStyle"></div>
                  <div class="second hand" [ngStyle]="clock.secondHandStyle"></div>
                  <div class="center-circle"></div>
                  <span class="a3">3</span>
                  <span class="a6">6</span>
                  <span class="a9">9</span>
                  <span class="a12">12</span>
                </div>
                <div class="digital-clock">
                  <!-- {{formatclock(clock.hour)}}:{{formatclock(clock.minute)}}:{{formatclock(clock.second)}} -->
                  {{clock.date}}

                  {{formatclock12Hrs(clock)}}
                </div>
              </div>
              <p class="countryname">
                {{clock.country}}
              </p>
            </div>
          </div>
          <div class="text-center notfound" *ngIf="timeZoneList.length==0">
            Records not found
          </div>
        </div>
      </div>
    </div>
    <!-- Ticket Summary -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.ticketsummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Ticket_summary.Ticket_summary' | translate }}</h5>
          <div class="type-drop">
            <select class="type-select form-control">
              <option *ngFor="let item of lastMonth">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="card-body height-strecht">
          <div class="picsum-img-wrapper">
            <div [chart]="ticketChart" *ngIf="ticketChart"></div>
            <div class="text-center notfound" *ngIf="!ticketChart">
              Records not found
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Task Summary -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.tasksummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Task_summary.Task_summary' | translate }}</h5>
          <div class="type-drop">
            <select class="type-select form-control">
              <option *ngFor="let item of lastMonth">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="card-body height-strecht">
          <div class="picsum-img-wrapper">
            <div [chart]="taskChart" *ngIf="taskChart"></div>
            <div class="text-center notfound" *ngIf="!taskChart">
              Records not found
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Snag Summary -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.snagsummary">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Snag_summary.Snag_summary' | translate }}</h5>
          <div class="type-drop">
            <select class="type-select form-control">
              <option *ngFor="let item of lastMonth">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="card-body height-strecht">
          <div class="picsum-img-wrapper">
            <div [chart]="snagChart" *ngIf="snagChart"></div>
            <div class="text-center notfound" *ngIf="!snagChart">
              Records not found
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EZB Costing -->
    <div class="col-lg-6 col-sm-12" *ngIf="dashboard.ezbcosting">
      <div class="card mb-1">
        <div class="card-header">
          <h5>{{ 'Ezb_Costing.Ezb_costing' | translate }}</h5>
          <div class="type-drop">
            <select class="type-select form-control" [(ngModel)]="ezbcostingFilter.monthyear"
              (change)="getHomeEzbCosting()">
              <option *ngFor="let item of lastMonth">{{item}}</option>
            </select>
            <button class="btn btn-primary gotoinvoice" title="Invoices" routerLink="/ezbcostanalysis"><i
                class="eb-invoices"></i></button>
          </div>
        </div>
        <div class="card-body height-strecht">
          <div class="picsum-img-wrapper">
            <div [chart]="invoiceChart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Reminde Modal -->
  <ng-template #content let-modal>
    <resize-border [dragHolder]="header">
      <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
          <h5 class="modal-title f-w-600" id="exampleModalLabel">Reminder</h5>
          <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
            <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
            <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
          </button>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="needs-validation">
            <div class="form row">
              <div class="form-group col-sm-12">
                <label for="title">Title </label>
                <input type="text" id="" class="form-control" name="title" [(ngModel)]="reminder.title">
              </div>
              <div class="form-group col-sm-12">
                <label for="description">Description</label>
                <textarea class="form-control" rows="5" placeholder="Notes" name="description"
                  [(ngModel)]="reminder.description"></textarea>
              </div>
              <div class="form-group col-sm-6">
                <label for="start_date">Start date </label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker14" placeholder="Choose a date"
                    [(ngModel)]="reminder.start_date" name="start_date">
                  <mat-datepicker-toggle matSuffix [for]="picker14"></mat-datepicker-toggle>
                  <mat-datepicker #picker14></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-6">
                <label for="end_date">End date </label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker15" placeholder="Choose a date" [(ngModel)]="reminder.end_date"
                    name="end_date">
                  <mat-datepicker-toggle matSuffix [for]="picker15"></mat-datepicker-toggle>
                  <mat-datepicker #picker15></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-6">
                <label for="due_date">Due date </label>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker16" placeholder="Choose a date" [(ngModel)]="reminder.due_date"
                    name="due_date">
                  <mat-datepicker-toggle matSuffix [for]="picker16"></mat-datepicker-toggle>
                  <mat-datepicker #picker16></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-6">
                <label for="type">Type</label>
                <select class="form-control" name="type" [(ngModel)]="reminder.type">
                  <option value="">Select Type</option>
                  <option [value]="typ.stringValue" *ngFor="let typ of reminderTypes">{{typ.Text}}</option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <label for="dept">Department</label>
                <select class="form-control" name="dept" [(ngModel)]="reminder.dept">
                  <option value="">Select Department</option>
                  <option [value]="n.Value" *ngFor="let n of departmentlist">{{n.Text}}</option>
                </select>
              </div>
              <div class="form-group col-sm-12">
                <label for="assign_to">Assign To</label>
                <!-- <select class="form-control" >
          <option [value]="n.Value" *ngFor="let n of employees">{{n.Text}}</option>
          </select> -->
                <ng-multiselect-dropdown [placeholder]="'Assign To'" [settings]="dropdownSettings"
                  [data]="technicianList" name="assign_to" [(ngModel)]="selectedAssignTo"
                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelect)="onItemDeSelect($event)">
                </ng-multiselect-dropdown>
              </div>
              <!-- <div class="form-group col-sm-12">
              <label for="pic">Pic</label>
              <input class="form-control" type="file" name="pic" [(ngModel)]="reminder.pic">
            </div> -->
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closeModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="submitReminder()">Save</button>
        </div>
      </div>
    </resize-border>
  </ng-template>
</div>
<!--End Employee Dashboard html -->
<ng-template #clockModal let-modal>
  <resize-border [dragHolder]="header2">
    <div class="whole-modal" [class.full-screen]="fullScreen">
      <div #header2 class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Select Reason</h5>
        <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
          <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
          <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
        </button>
        <button type="button" class="close" aria-label="Close" (click)="closeCLockingModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form row">
          <div class="col-lg-12 form-group">
            <label for="monthlyStatement">Reasons</label>
            <select name="" id="" class="form-control">
              <option value="">Select reason</option>
              <option *ngFor="let n of reasonsList" value="n.Value">{{n.Text}}</option>
            </select>
          </div>
          <div class="form-group col-lg-12 col-md-12 col-sm-12">
            <label for="">Other reason</label>
            <textarea rows="5" type="text" class="form-control" name="otherReason" [(ngModel)]="otherReason"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
          (click)="closeCLockingModal()">Close</button>
        <button type="button" *ngIf="buttonType=='break'" class="btn btn-primary" (click)="pause()">Save</button>
        <button type="button" *ngIf="buttonType!='break'" class="btn btn-primary" (click)="startShift()">Save</button>
      </div>
    </div>
  </resize-border>
</ng-template>

<!-- <ng-template #expiryModal let-modal>
  <div class="expiry-popup">
    <div class="expiryclose-button" (click)="closepopup()">
      <i class="fa fa-times"></i>
    </div>
    <div>
      <div class="icons-div">
        <img src="assets/images/expiry.png">
      </div>
      <div class="expiry-text">Your ezybooks subscription is about to end soon please renew your subscription</div>
      <div class="sub-link">To renew your subscription or to update your subscription <button
          (click)="gotoSubscriptions()">click here</button>
      </div>
    </div>
  </div>
</ng-template> -->
<ngx-ui-loader></ngx-ui-loader>