<div class="padding-div">
    <div class="form-row">
        <div class=" col-lg-12 col-md-12 form-group">
            <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text"
                class="form-control" placeholder="Search by name, email" [(ngModel)]="searchtxt"
                (input)="getSourceList()">
        </div>
    </div>
    <div class="category-table custom-datatable mt-1">
        <div class="table-responsive">
            <table class="custom-table">
                <tr class="custom-tr">
                    <th class="custom-th text-center">ID</th>
                    <th class="custom-th text-center">Number</th>
                    <th class="custom-th text-center">Party Type</th>
                    <th class="custom-th text-center">Party Name</th>
                </tr>
                <tr class="custom-tr" *ngFor="let item of sourceList">
                    <td class="custom-td text-center"><span class="hyperlink" (click)="selectSource(item)"
                            ngbDropdownItem>{{item.source_id}}</span>
                    </td>
                    <td class="custom-td text-left"><span class="hyperlink" (click)="selectSource(item)"
                            ngbDropdownItem>{{item.source_no}}</span>
                    </td>
                    <td class="custom-td text-left"><span class="hyperlink" (click)="selectSource(item)"
                            ngbDropdownItem>{{item.party_type}}</span>
                    </td>
                    <td class="custom-td text-left"><span class="hyperlink" (click)="selectSource(item)"
                            ngbDropdownItem>{{item.party_name}}</span>
                    </td>
                </tr>
            </table>
            <div class="no-results" *ngIf="sourceList.length == 0">Your search result yielded no results</div>
        </div>
    </div>
</div>