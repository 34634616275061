<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <form (keydown.enter)="gotoPage('first')">
          <div class="custom-row">
            <div class="form-row">
              <div class="col-lg-6 pr-0">
                <div class="row">
                  <div class="col-lg-9 col-md-6 form-group">
                    <label for="">{{ 'Index_Filter.Search_Label' | translate }}</label>
                    <input type="search" class="form-control" [(ngModel)]="filter.searchtxt" name="searchtxt"
                      placeholder="{{ 'Index_Filter.Search_Placeholder' | translate }}" (change)="gotoPage('first')">
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <label for="">{{ 'Index_Filter.Type_Label' | translate }}</label>
                    <select class="form-control" required="" name="type" [(ngModel)]="filter.type"
                      (change)="gotoPage('first')">
                      <option value="">{{ 'Index_Filter.Type_Placeholder' | translate }}</option>
                      <option *ngFor="let item of productType" [value]="item.stringValue">{{item.Text}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 p-0">
                <div class="row">
                  <div class="col-lg-3 col-md-6 form-group p-o">
                    <label for="">{{ 'Index_Filter.Department_Label' | translate }}</label>
                    <ng-select placeholder="{{ 'Index_Filter.Department_Placeholder' | translate }}" required=""
                      name="deptid" [(ngModel)]="filter.deptid" (ngModelChange)="selectDepartment($event)"
                      [clearable]="filter.deptid">
                      <ng-option *ngFor="let item of deptList" [value]="item.Value">
                        {{item.Text}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group pl-0">
                    <label for="">{{ 'Index_Filter.Category_Label' | translate }}</label>
                    <select class="form-control" required="" name="cat" [(ngModel)]="filter.category"
                      (change)="gotoPage('first')">
                      <option value="">{{ 'Index_Filter.Category_Placeholder' | translate }}</option>
                      <option *ngFor="let item of categoryList" [value]="item.Value">{{item.Text}}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group pl-0">
                    <label for="">{{ 'Index_Filter.Status_Label' | translate }}</label>
                    <!-- <select class="form-control" required="" name="status" [(ngModel)]="filter.status"
                                        (change)="gotoPage('first')">
                                        <option value="">All</option>
                                        <option *ngFor="let item of statusList" [value]="item.stringValue">
                                            {{item.Text}}
                                        </option>
                                    </select> -->
                    <ng-multiselect-dropdown placeholder="{{ 'Index_Filter.Status_Placeholder' | translate }}"
                      [settings]="dropdownSettings" [data]="statusList" name="status"
                      [(ngModel)]="selectedProductstatus" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group p-0">
                    <label for="">{{ 'Index_Filter.Serialized_Label' | translate }}</label>
                    <select class="form-control" required="" name="Serilized" [(ngModel)]="filter.serilized"
                      (change)="gotoPage('first')">
                      <option value="">{{ 'Index_Filter.Serialized_Placeholder' | translate }}</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
              <button type="button" container="body" ngbTooltip="{{ 'Index_Filter.Search_Button' | translate }}"
                class="btn btn-primary right-radius" (click)="gotoPage('first')"><i
                  class="refresh-btn fa fa-search"></i></button>
              <button type="button" container="body" ngbTooltip="{{ 'Index_Filter.Refresh_Button' | translate }}"
                class="ml-2 btn btn-primary right-radius left-radius" (click)="refresh()"><i
                  class="refresh-btn fa fa-refresh"></i></button>
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="goToAdd()">{{ 'Index_Filter.Filter_Options.AddProduct_Option' |
                      translate }}</span></li>
                  <li><span ngbDropdownItem (click)="export('csv')">{{ 'Index_Filter.Filter_Options.ExportCSV_Option' |
                      translate }}</span></li>
                  <li><span ngbDropdownItem (click)="export('xlsx')">{{ 'Index_Filter.Filter_Options.ExportExcel_Option'
                      | translate }}</span></li>
                  <li><span ngbDropdownItem (click)="export('pdf')">{{ 'Index_Filter.Filter_Options.ExportPDF_Option' |
                      translate }}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="category-table custom-datatable product-list">
          <div class="table-responsive">
            <table class="input-table">
              <thead>
                <tr class="custom-tr">
                  <th class="custom-th text-center">{{ 'Index_Table.Actions_Column' | translate }}</th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'ilc')">{{ 'Index_Table.ILCNum_Column' |
                    translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'description')">{{
                    'Index_Table.Description_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'suppliername')">{{
                    'Index_Table.Supplier_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'dept_name')">{{
                    'Index_Table.Department_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'category')">{{
                    'Index_Table.Category_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'minqty')">{{
                    'Index_Table.MinQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'maxqty')">{{
                    'Index_Table.MaxQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'reorderpoint')">{{
                    'Index_Table.Reorderpoint_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'qtyonhand')">{{
                    'Index_Table.OnHandQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'qtyhold')">{{
                    'Index_Table.OnHoldQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'qtyavail')">{{
                    'Index_Table.AvailableQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'qtyonorder')">{{
                    'Index_Table.OnOrderQty_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'nos')">{{
                    'Index_Table.SerialNumbers_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'retailprice')">{{
                    'Index_Table.RetailPrice_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'pocost')">{{
                    'Index_Table.POCost_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'landedcost')">{{
                    'Index_Table.LandedCost_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'status')">{{
                    'Index_Table.Status_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'filename')">{{
                    'Index_Table.Picture_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'ptype')">{{ 'Index_Table.Type_Column' |
                    translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'tpcode')">{{
                    'Index_Table.TPCode_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'tpcodeextra')">{{
                    'Index_Table.TPCodeExtra_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'isserilized')">{{
                    'Index_Table.Serialized_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'tpsync')">{{
                    'Index_Table.TPSync_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'avgcost')">{{
                    'Index_Table.AvgCost_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'inventory_lead_time')">{{
                    'Index_Table.InvLeadTime_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'production_lead_time')">{{
                    'Index_Table.ProdLeadTime_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'prefer_to_produce')">{{
                    'Index_Table.PreferToProduce_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'last_purchase_date')">{{
                    'Index_Table.LastPurchaseDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'prefer_to_purchase')">{{
                    'Index_Table.PreferToPurchase_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'id')">{{ 'Index_Table.ID_Column' |
                    translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'createby')">{{
                    'Index_Table.CreateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">{{
                    'Index_Table.CreateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">{{
                    'Index_Table.UpdateBy_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                  <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">{{
                    'Index_Table.UpdateDate_Column' | translate }} <i class="Sorting fa fa-chevron-up"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr class="custom-tr" *ngFor="let product of products">
                  <td class="custom-td text-center">
                    <div ngbDropdown class="d-inline-block ActionCell" container="body">
                      <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                      <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                        <li *ngFor="let n of menuActions"><span ngbDropdownItem
                            (click)="clicked(n.title,product)">{{n.title}}</span></li>
                      </ul>
                    </div>
                  </td>
                  <td class="custom-td text-center" (click)="gotoEdit(product)" style="color:blue;cursor: pointer;">{{
                    product.ilc }}</td>
                  <td class="custom-td text-left">
                    <div class="csstooltip position-relative">
                      <div class="show-less position-relative">
                        {{ product.description ? product.description : '' }}
                      </div>
                      <span class="tooltiptext">{{ product.description }}</span>
                    </div>
                  </td>
                  <td class="custom-td text-left">{{ product.suppliername }}</td>
                  <td class="custom-td text-left">{{ product.dept_name }}</td>
                  <td class="custom-td text-left">{{ product.category }}</td>
                  <td class="custom-td text-left">{{ product.minqty }}</td>
                  <td class="custom-td text-left">{{ product.maxqty }}</td>
                  <td class="custom-td text-left">{{ product.reorderpoint }}</td>
                  <td class="custom-td text-left">
                    <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal"
                      (click)="coloumclick(product)">{{product.qtyonhand }}</div>
                  </td>
                  <td class="custom-td text-left">
                    <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal"
                      (click)="coloumclick(product)">{{ product.qtyhold }}</div>
                  </td>

                  <td class="custom-td text-left">
                    <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal"
                      (click)="coloumclick(product)">{{ product.qtyavail }}</div>
                  </td>
                  <td class="custom-td text-left">
                    <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal"
                      (click)="onorderclick(product)">{{ product.qtyonorder }} </div>
                  </td>
                  <td class="custom-td text-center">
                    <div class="link cursor-pointer" data-toggle="modal" data-target="#exampleModal"
                      (click)="onserialclick(product)">{{ product.nos }}</div>
                  </td>
                  <td class="custom-td text-left">{{ product.retailprice }}</td>
                  <td class="custom-td text-left">{{ product.pocost }}</td>
                  <td class="custom-td text-left">{{ product.landedcost }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">
                    <span class="badge" title="{{ product.status}}">{{ product.status }}</span>
                  </td>
                  <td class="custom-td text-left" style="text-align: center !important;">
                    <a href="{{ product.filename }}" target="_blank">
                      <img width="50px" src="{{ product.filename }}" />
                    </a>
                  </td>
                  <td class="custom-td text-left">{{ product.ptype }}</td>
                  <td class="custom-td text-left">{{ product.tpcode }}</td>
                  <td class="custom-td text-left">{{ product.tpcodeextra }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.isserilized }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.tpsync }}</td>
                  <td class="custom-td text-left">{{ product.avgcost }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.inventory_lead_time
                    }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.production_lead_time
                    }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.prefer_to_produce }}
                  </td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.last_purchase_date |
                    date: userdata.date_format }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.prefer_to_purchase
                    }}</td>
                  <td class="custom-td text-left">{{ product.id }}</td>
                  <td class="custom-td text-left">{{ product.createby }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.createdate | date:
                    userdata.displaydatetimeformat }}</td>
                  <td class="custom-td text-left">{{ product.updateby }}</td>
                  <td class="custom-td text-left" style="text-align: center !important;">{{ product.updatedate | date:
                    userdata.displaydatetimeformat }}</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div class="total-count justify-content-between" *ngIf="totalRows">
            <span><b class="mr-1">{{'Totalrow_Label' | translate }} : </b> {{totalRows}}</span>
            <div class="pagination">
              <span class="right-button arrow-btn" [class.disabled]="hideRightNav" (click)="gotoPage('first')"><i
                  class="fa fa-angle-double-left"></i></span>
              <span class="right-button arrow-btn" [class.disabled]="hideRightNav" (click)="gotoPage('prev')"><i
                  class="fa fa-angle-left"></i></span>
              <div aria-current="page" class="igx-page-nav__text">
                <span>{{currentPage}}</span><span>&nbsp;of&nbsp;</span><span>{{totalPages}}</span>
              </div>
              <span class="arrow-btn" [class.disabled]="hideLeftNav || currentPage==totalPages"
                (click)="gotoPage('next')"><i class="fa fa-angle-right"></i></span>
              <span class="arrow-btn" [class.disabled]="hideLeftNav || currentPage==totalPages"
                (click)="gotoPage('last')"><i class="fa fa-angle-double-right"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>