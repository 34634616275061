import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Events } from 'src/app/shared/service/events.service';
import { environment } from '../../../environments/environment';
// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class serviceManagerServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    tempdata: any;

    userprofile: any;
    contractIdForEdit: any = '';
    ticketIdForEdit: any = '';
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }
    //get service contract 
    getServiceContract(pg, searchtxt, categoryid, subcategoryid, status) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&categoryid=' + categoryid + '&subcategoryid=' + subcategoryid + '&status=' + status);
    }

    getTickets(pg, category, searchtxt, status, tags, assignto, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Tickets/Get_TicketIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&category=' + category + '&searchtxt=' + searchtxt + '&status=' + status + '&tags=' + tags + '&assignto=' + assignto + '&fdate=' + fdate + '&edate=' + edate);
    }

    getTicketsForBatchPrint(fromdate, todate, status,) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_Tickets2Print?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }

    getServiceContractPeriods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServicePeriod?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCategory(parentid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&parentid=' + parentid)
    }
    getContractStatus(name_of_field) {
        return this.http.get(this.baseUrl + 'api/Comman/GetComboEnums?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name_of_field=' + name_of_field)
    }
    getContractTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetContractTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    deleteContract(id) {
        return this.http.get(this.baseUrl + 'api/Contracts/DeleteContract?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSites(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerSites?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid)
    }
    //create contract to invoice
    createContract2Inv(id) {
        return this.http.get(this.baseUrl + 'api/invoices/Gett_Contract2Invoice?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    //create ticket to invoice
    createTicket2Inv(id) {
        return this.http.get(this.baseUrl + 'api/invoices/Gett_Ticket2Invoice?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    getContractSchduleList(contractid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetContractSchedules?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + contractid)
    }
    getContractDetails(id) {
        return this.http.get(this.baseUrl + 'api/Contracts/Gett_ContractByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    DeleteLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/Contracts/DeleteContractLineItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteContractDetailsItem(id) {
        return this.http.delete(this.baseUrl + 'api/Contracts/DeleteContractDetailsLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getGetCreateScheduleFor() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCreateScheduleFor?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postContract(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Contracts/Postt_Contract', data);
    }
    postTicket(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tickets/Postt_Ticket', data);
    }
    getTicketDetails(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTicketItems(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_TicketItems', val);
    }
    postTicketCards(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_TicketCards', val);
    }

    getAllTimeLog(tech, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_UnAuditedTechHours?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tech=' + tech + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    postAuditTechHours(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_AuditTechHours', val);
    }
    postTicketNotes(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_TicketNotes', val);
    }
    postTechNotes(val) {
        return this.http.post(this.baseUrl + 'api/Checklists/Post_Technotes', val);
    }


    postToggleNotes(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_ToggleTicketNotes', val);
    }

    postTicketManagerNotes(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_TicketManagerNotes', val);
    }
    getNotesDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Get_TicketNotesByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    postDeleteNotes(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_TicketLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTicketChecklist(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/GetTicketChecklists?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postTicketCheckList(val) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_TicketChecklist', val);
    }

    postDeleteChecklist(tid, deviceid) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_CheckList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + tid + '&deviceid=' + deviceid);
    }
    postDeleteChecklistItem(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_CheckListItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeleteTicketEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_TicketItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeleteTicketCardEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_TicketCard?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeleteTicketItemLocation(id, tiid) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_TicketItemLocation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tiid=' + tiid);
    }
    //Tracking
    postTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Contracts/Post_TrackingNotes', data);
    }
    postQuickUpdate(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Contracts/Postt_QuickUpdateContract', data);
    }
    // ticket category

    getTicketItems(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Get_TicketItems?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketCategories(name, code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ServiceCategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code)
    }
    getTicketCategoryList(parentid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&parentid=' + parentid)
    }

    getCategoryById(id) {
        return this.http.get(this.baseUrl + 'api/master/Gett_TicketCategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCategory(data) {
        return this.http.post(this.baseUrl + 'api/master/Postt_ServiceCategory', data);
    }
    getChecklists(pg, displayname, status, group, description) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_Checklists?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&groupid=' + group + '&displayname=' + displayname + '&description=' + description + '&status=' + status)
    }
    getChecklistsDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_ChecklistByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketMaterials(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketMaterials?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    getTicketLocalPurchase(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketLocalPurchase?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    getTicketExpenses(id, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketExpenses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    getTicketInvoices(id, startdate, enddate, invno) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id + '&startdate=' + startdate + '&enddate=' + enddate + '&invno=' + invno);
    }
    getTicketLogs(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    getTicketTimeLogs(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketTimeLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    postCheckList(data) {
        return this.http.post(this.baseUrl + 'api/Checklists/Postt_Checklist', data);
    }
    deleteCheckList(id) {
        return this.http.delete(this.baseUrl + 'api/Checklists/Delete_Checklist?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTechRecommendations(pg, searchtxt, type, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketRecommdations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getTicketsUsingCustomer(customer) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer);
    }
    postTicketQuickUpdate(data) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_QuickUpdate', data);
    }
    getDepleteStock(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Deplete_TicketItems?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketToSalesOrder(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_Ticket2SalesOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTechNotes(desc, type) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_Technotes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&desc=' + desc + '&type=' + type);
    }
    deleteTechNotes(id) {
        return this.http.delete(this.baseUrl + 'api/Checklists/Delete_TechNotes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCheckListGroup(data) {
        return this.http.post(this.baseUrl + 'api/Checklists/Postt_Checklistgroup', data);
    }
    getChecklistgroupByID(id) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_ChecklistgroupByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckListGroupIndex(name, type, status) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_Checklistgroups?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&type=' + type + '&status=' + status);
    }

    deleteCheckListGroup(id) {
        return this.http.delete(this.baseUrl + 'api/Checklists/Delete_ChecklistGroup?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckListGroupList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceChecklistGrp?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckListById(id) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_ChecklistByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckListOptions(id) {
        return this.http.get(this.baseUrl + 'api/Checklists/Get_ChecklistoptionsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCheckListOptions(id) {
        return this.http.delete(this.baseUrl + 'api/Checklists/Delete_ChecklistOption?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCheckListOptions(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Checklists/Postt_Checklistoptions', data);
    }
    deleteTicket(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_Ticket?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteTicketCategory(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ServiceCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getApprovedTickets(customerid) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_ApprovedTicketRecommdations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid)
    }
    saveRecomTicket(data) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_Recommendations2Ticket', data);
    }
    saveRecomQuote(data) {
        return this.http.post(this.baseUrl + 'api/Quotes/Post_Recommendations2Quote', data);
    }

    //Ticket Trip tab
    getTicketSchedule(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketSchedules?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id);
    }
    getTripById(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_TicketTripByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTrip(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tickets/Postt_TicketTrip', data);
    }
    deleteTrip(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_TicketTrip?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getContractInvoice(id, invno, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + id + '&fdate=' + fdate + '&tdate=' + tdate + '&invno=' + invno);
    }
    getContractTrackingNotes(id) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractTrackings?contractid=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getContractSchedule(id, ticketno, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + id + '&ticketno=' + ticketno + '&fdate=' + fdate + '&tdate=' + tdate);

    }
    getRecurrance(id, active, fdate, tdate, recur_pattern) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractRecurrences?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + id + '&fdate=' + fdate + '&tdate=' + tdate + '&recur_pattern=' + recur_pattern + '&active=' + active);
    }
    getRecurranceByID(id) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractRecurrenceByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteRecurrance(id) {
        return this.http.delete(this.baseUrl + 'api/Contracts/Delete_ContractRecurrence?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postRecurrance(val, id) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Contracts/Post_ContractRecurrence?is_del_future_tickets=' + id, data);
    }
    getBillingScheduleList(id, fdate, tdate, recur_pattern, active) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractBillingSchedule?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + id + '&fdate=' + fdate + '&tdate=' + tdate + '&recur_pattern=' + recur_pattern + '&active=' + active);
    }
    getBillingScheduleByID(id) {
        return this.http.get(this.baseUrl + 'api/Contracts/Get_ContractBillingScheduleByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteBillingSchedule(id) {
        return this.http.delete(this.baseUrl + 'api/Contracts/Delete_ContractBilling?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBillingSchedule(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Contracts/Post_ContractBilling', data);
    }

    getUnauditedItems(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_UnAuditedItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id)
    }

    getUnauditedCards(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_UnAuditedTimeCards?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tid=' + id)
    }

    postAuditedItem(data) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_AuditItems', data)
    }
    getCustomerDevices(pg, searchtxt, type) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_CustomerDeviceIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&type=' + type);
    }
    postAuditedCard(data) {
        return this.http.post(this.baseUrl + 'api/Tickets/Post_AuditTimeCards', data)
    }
    //Non billable time
    getNonBillableTimeIndex(pg, tech, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_NonBillableTimeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&tech=' + tech + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getNonBillableTimeByID(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_NonBillableTimeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteNonBillableTime(id) {
        return this.http.delete(this.baseUrl + 'api/Tickets/Delete_NonBillableTime?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postNonBillableTime(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tickets/Postt_NonBillableTime', data);
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
