import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';
import { PartysComponent } from '../partysmodal/partysmodal.component';

@Component({
  selector: 'app-loanapplication',
  templateUrl: './loanapplication.component.html',
  styleUrls: ['./loanapplication.component.scss']
})
export class LoanApplicationModalComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  userdata: any;
  private langChangeSubscription: Subscription; 
  appname = environment.APP_NAME;
  contactList: any = [];
  selectedContact: any;
  partyTypeList: any = [];
  loanAppStatus: any = [];
  loanTypes: any = [];
  loanapp: any = {
    "id": null,
    "companyid": null,
    "applicationdate": null,
    "loantype": "",
    "requested_amt": 0,
    "currency": "",
    "status": "submitted",
    "approved_amt": 0,
    "approveby": "",
    "approvedate": "",
    "loanappno": "",
    "createby": "",
    "createdate": "",
    "updateby": "",
    "updatedate": "",
    "deleteby": "",
    "deletedate": "",
    "seq": null,
    "party_type": "",
    "party_id": null,
    "priority": "medium",
    "expected_date": "",
    "party_name": "",
    "category": ""
  }
  currencyList: any = [];
  priorityList: any = [];
  fieldsetDisabled = false;
  isShowFields = true;
  uploader: FileUploader;
  filename: any;
  response: any;
  t_loan_app_documents: any = [];
  uploadedFiles: any = [];
  loanCatList: any = [];
  isSaved = false;
  @Input() params;
  @ViewChild(PartysComponent, { static: false }) PartyComponent: PartysComponent;
  constructor(
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private datepipe: DatePipe, private service: accountServices) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    this.loanapp.applicationdate = datepipe.transform(new Date(), this.userdata.date_format);
    this.commonService.getComboEnums('partytype').subscribe(res => {
      this.partyTypeList = res;
    })
    this.commonService.getCurrency().subscribe(res => {
      this.currencyList = res;
    }, err => {
    });
    this.commonService.getComboEnums('loanapppriorities').subscribe(res => {
      this.priorityList = res;
    }, err => {
    });
    this.commonService.getComboEnums('loanappstatus').subscribe(res => {
      this.loanAppStatus = res;
    }, err => {
    });
    this.commonService.getComboEnums('loancategory').subscribe(res => {
      this.loanCatList = res;
    }, err => {
    });
  }
  selectContact(item) {
    this.selectedContact = item;
    this.closeModal();
  }

  ngOnInit(): void {
    if (this.params.id) {
      this.getLoanApplicationById(this.params.id);
    } else if (this.params.party_id) {
      this.loanapp.party_id = this.params.party_id;
      this.loanapp.party_name = this.params.party_name;
      this.loanapp.party_type = this.params.party_type;
    }
    if (this.params.from == 'userprofile') {
      this.isShowFields = false;
    }
    this.initializeFileUploader();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    }); 
  }

    
private loadComponentTranslations(lang: string) { 
  const translationFilePath = `./assets/i18n/hr/loanapplications/${lang}.json`; 
  this.http.get(translationFilePath).subscribe((translations:any) => { 
  this.translate.setTranslation(lang, translations, true); 
  this.translate.use(lang); 
  }); 
   } 
  


  getLoanType() {
    if (!this.loanapp.category) {
      return;
    }
    
    this.loader.start();
    this.commonService.getLoanType(this.loanapp.category).subscribe(res => {
      this.loader.stop();
      this.loanTypes = res;
    }, err => {
      this.loader.stop();
    })
  }
  getLoanApplicationById(id) {
    this.loader.start();
    this.service.getLoanApplicationById(id).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        this.loanapp = res;
        
        this.uploadedFiles = this.loanapp.documents;
        this.uploadedFiles.forEach(element => {
          element.file_url = environment.DOC_URL + '/' + this.userdata.companyid + '/' + element.file_name
        });
        this.getLoanType();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading lead contact ', err);
    });
  }
  selectPartyType() {
    this.loanapp.party_id = null;
    this.loanapp.party_name = '';
    this.PartyComponent.type = this.loanapp.party_type;
  }
  selectedParty() {
    this.PartyComponent.type = this.loanapp.party_type;
    if (this.PartyComponent.selectedParty) {
      if (this.PartyComponent.selectedParty == null || this.PartyComponent.selectedParty == '') {
        return;
      }
      this.loanapp.party_id = this.PartyComponent.selectedParty.Value;
      this.loanapp.party_name = this.PartyComponent.selectedParty.Text;
      this.loanapp.currency = this.PartyComponent.selectedParty.Value4;
      this.PartyComponent.selectedParty = '';
    }
  }
  openFile(file) {

  }
  saveloanapp() {
    if ((this.loanapp.id)) {
      this.loanapp.updateby = this.userdata.email;
      this.loanapp.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.loanapp.createdate = this.datepipe.transform(new Date(this.loanapp.createdate), this.userdata.postdatetimeformat);
      // this.loanapp.t_loan_app_documents.forEach(item => {
      //   this.t_loan_app_documents.push(item);
      // })
    } else {
      this.loanapp.companyid = this.userdata.companyid;
      this.loanapp.createby = this.userdata.email;
      this.loanapp.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    if(!this.loanapp.currency) {
      this.loanapp.currency = this.userdata.currency;
    }
    this.loanapp.expected_date = this.loanapp.expected_date ? this.datepipe.transform(new Date(this.loanapp.expected_date), this.userdata.postdatetimeformat) : null;
    this.loanapp.applicationdate = this.loanapp.applicationdate ? this.datepipe.transform(new Date(this.loanapp.applicationdate), this.userdata.postdatetimeformat) : null;
    this.loanapp.approvedate = this.loanapp.approvedate ? this.datepipe.transform(new Date(this.loanapp.approvedate), this.userdata.postdatetimeformat) : null;
    this.loanapp.t_loan_app_documents = this.t_loan_app_documents;
    this.loader.start();
    this.service.postLoanApplication(this.loanapp).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        if (this.t_loan_app_documents.length > 0) {
          this.uploadFile()
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading lead contact ', err);
    });
  }
  //File uploader code
  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop()
  }

  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('createby', this.userdata.email);
      form.append('refno', this.loanapp.id);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'loan-files');
    };
    this.response = '';
    this.uploader.response.subscribe((res: any) => {
      this.loader.stop();
      res = JSON.parse(res);
      if (res != null) {
        this.response = res.data;
      }
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onAfterAddingAll = (files: any) => {
      files.forEach(fileItem => {
        // fileItem.file.name = fileItem.file.name.replace(/\.[^/.]+$/, "");
        environment.production ? '' : console.log('file name: ' + fileItem.file.name);
        this.t_loan_app_documents.push({
          "loan_app_id": this.loanapp.id,
          "file_name": fileItem.file.name,
          "active": true,
          "companyid": this.userdata.companyid,
          "createby": this.userdata.email,
          "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        })
      });
    };
  }
  uploadFile() {
    this.t_loan_app_documents.forEach((file, index) => {
      if (this.t_loan_app_documents.length - 1 == index) {
        this.t_loan_app_documents = [];
      }
      this.uploader.queue[index].onSuccess = function (response, status, headers) {
        if (status == 200) {
          let tempRes = JSON.parse(response);
          // this.toastr.Success("File uploaded sucessfully")
          environment.production ? '' : console.log('upload', status);
        } else {
          // this.toastr.Success("File uploaded falid")
          environment.production ? '' : console.log('upload faild', status);
          // Swal.fire('', 'File uploaded', 'success');
        }
      };
      this.uploader.queue[index].upload();
    })
  }
  cancelAttachment() {
    this.t_loan_app_documents = [];
  }
  closeModal() {
    this.activeModal.close(this.isSaved ? 'save' : 'closed');
  }
}
