import { NgModule } from '@angular/core';

import { SourcelistModalComponent } from './sourcelist.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [SourcelistModalComponent],
    imports: [CommonModule, FormsModule, NgbModule, NgbTooltipModule, AutocompleteLibModule, NgSelectModule],
    exports: [SourcelistModalComponent],
})
export class SourcelistModule { }