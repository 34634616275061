// import { Injectable } from '@angular/core';
// import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from '../../../environments/environment'
// import { throwError, Observable } from 'rxjs';
// import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
// import { catchError } from 'rxjs/operators';
// import { Events } from 'src/app/shared/service/events.service';
// import { DatePipe } from '@angular/common';

import { EventEmitter, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class IntegrationServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    //QB services
    getQBAuthenticate() {
        return this.http.get(this.baseUrl + 'api/QB/AuthenticateQuickBook?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getQBRefreshToken(appid) {
        return this.http.get(this.baseUrl + 'api/QB/RefreshToken?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&app_id=' + appid);
    }
    getQBSyncCustomer(appid, synctimestamp, type, custid) {
        return this.http.get(this.baseUrl + 'api/QB/Sync_Customer?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&app_id=' + appid + '&sync_timestamp=' + synctimestamp + '&type=' + type + '&customerid=' + custid);
    }
    getQBSyncSuppliers(appid, synctimestamp, type, custid) {
        return this.http.get(this.baseUrl + 'api/QB/Sync_Customer?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&app_id=' + appid + '&sync_timestamp=' + synctimestamp + '&type=' + type + '&supplierid=' + custid);
    }

    // Xero Services
    getXeroAuthenticate() {
        return this.http.get(this.baseUrl + 'api/Xero/AuthenticateXero?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getXeroRefreshToken(appid) {
        return this.http.get(this.baseUrl + 'api/Xero/RefreshToken?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&app_id=' + appid);
    }
    //batches
    postBatch(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/TPI/Post_TPIBatch', data);
    }
    getBatchListIndex(pg, searchtxt, fromdate, todate, type, txntype, status) {
        return this.http.get(this.baseUrl + 'api/TPI/Get_TPIBatches?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg + '&rw=' + this.userprofile.grid_page_size + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&txntype=' + txntype + '&fromdate=' + fromdate + '&todate=' + todate)
    }

    getBatchByID(id) {
        return this.http.get(this.baseUrl + 'api/TPI/Get_TPIBatchByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    getBatchEntries(id) {
        return this.http.get(this.baseUrl + 'api/TPI/Get_TPIBatchData?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    deleteBatch(id) {
        return this.http.delete(this.baseUrl + 'api/TPI/Delete_TPIBacth?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteBatchEntry(id) {
        return this.http.delete(this.baseUrl + 'api/TPI/Delete_TPIBacthEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
