<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="paymentModalLabel">{{ 'Modal_Title' | translate }}

            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.Reference_Label' | translate }}</label>
                    <input type="text" class="form-control" name="referanceno" [(ngModel)]="refno" readonly>
                </div>
                <div class="col-lg-6 col-md-6 form-group">
                    <label for="" class="required">{{ 'Modal_Inputs.Title_Label' | translate }}</label>

                    <input type="text" class="form-control" name="title" [(ngModel)]="title">
                </div>

                <div class="col-lg-6 col-md-6 form-group">
                    <label for="">{{ 'Modal_Inputs.DocumentType_Label' | translate }}</label>
                    <div class="input-group ">
                        <select name="" class="form-control" id="" name="doctype" [(ngModel)]="docType">
                            <option value="">{{ 'Modal_Inputs.DocumentType_Placeholder' | translate }}</option>

                            <option *ngFor="let item of docTypeList" [value]="item.Value">
                                {{item.Text}}</option>
                        </select>
                        <div class="input-group-append" ngbTooltip="{{ 'Modal_Inputs.AddDocuments_Tooltip' | translate }}">
                            <span class="input-group-text btn-primary cursor-pointer"  container="body"
                                id="basic-addon2" (click)="openMasterModal()" 
                                [class.disabled]="fieldsetDisabled"><i   class="fa fa-plus"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6 form-group">
            <label for="">Type</label>
            <select name="" class="form-control" id="" name="doctype" [(ngModel)]="docType">
                <option value="">--select--</option>
                <option *ngFor="let item of docTypeList" [value]="item.Value">
                    {{item.Text}}</option>
            </select>
        </div> -->
                <div class="col-lg-6 col-md-6 form-group">

                    <label for="" class="required">{{ 'Modal_Inputs.File_Label' | translate }}</label>
                    <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
                        (change)="selectedFileOnChanged($event)" [(ngModel)]="dcument"
                        accept="application/pdf, image/gif, image/jpeg, image/png" />
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"

                (click)="closeModal('close')">
                {{ 'Modal_Buttons.Close_Button' | translate }}</button>
            <button class="btn btn-primary" type="button" (click)="uploadFile()">  {{ 'Modal_Buttons.Save_Button' | translate }}</button>


            <div class="table-responsive2 mt-3">
                <table class="custom-table">
                    <thead>
                        <tr class="custom-tr">
                            <th class="custom-th text-center" style="width:5% ;">{{ 'Modal_Table.Actions_Column' | translate }}</th>
                            <th class="custom-th text-center">{{ 'Modal_Table.Title_Column' | translate }}</th>
                            <th class="custom-th text-center">{{ 'Modal_Table.Document_Column' | translate }}</th>
                            <th class="custom-th text-center">{{ 'Modal_Table.Type_Column' | translate }}</th>
                            <th class="custom-th text-center">{{ 'Modal_Table.Status_Column' | translate }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr" *ngFor="let item of docList">
                            <td class="custom-td text-center">
                                <div class="ActionBtn">
                                    <a class="hoverBtn" (click)="deleteDocument(item.id)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </div>
                            </td>
                            <td class="custom-td text-left">{{item.description}}</td>
                            <td class="custom-td">
                                <span class="blue-links"><a target="_blank" [href]="item.file_url">
                                        {{item.name}}</a></span>
                            </td>
                            <td class="custom-td text-center">{{item.dtype}}</td>
                            <td class="custom-td text-center " >{{item.status}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</resize-border>